<template>
  <TModal
    title="Add new child transaction"
    :show.sync="show"
    size="xl"
    @click-create="create"
    :creating="creating"
  >
    <CRow>
      <CCol md="7">
        <CRow>
          <CCol>
            <SSelectTransactionType
              label="Type"
              :value.sync="input.type_id"
              class="mb-3"
              :prepend="[]"
            />
          </CCol>
        </CRow>
        <!-- <div>
          <TInputRadio
            label="Arising from"
            :list="arisingFromTypes"
            :value.sync="temp.arising_from"
            custom
          />
        </div>
        <CRow v-if="temp.arising_from === 'card'">
          <CCol md="6">
            <SSelectCard
              placeholder="Card"
              class="mb-3"
              :value.sync="input.card_id"
            />
          </CCol>
          <CCol md="6">
            <TInputText
              placeholder="Ref code"
              class="mb-3"
              :value.sync="input.code_ref"
            />
          </CCol>
        </CRow>
        <CRow v-if="temp.arising_from === 'transaction'">
          <CCol>
            <SSelectTransaction
              class="mb-3"
              :value.sync="input.transaction_parent_id"
            />
          </CCol>
        </CRow> -->
        <CRow>
          <CCol>
            <div>
              <TInputRadio
                label="Object"
                :list="transactionableTypes"
                :value.sync="input.transactionable_type"
                custom
              />
            </div>
            <SSelectCustomer
              v-if="input.transactionable_type === 'user'"
              :value.sync="input.transactionable_id"
              class="mb-3"
            />
            <SSelectSupplier
              v-if="input.transactionable_type === 'supplier'"
              :value.sync="input.transactionable_id"
              class="mb-3"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CRow>
              <CCol>
                <TInputDateTime
                  label="Voucher date"
                  class="mb-3"
                  :value.sync="input.voucher_date"
                />
              </CCol>
              <CCol>
                <!-- <SSelectCurrency
                  label="Currency"
                  :prepend="[]"
                  :value.sync="input.currency_id"
                /> -->
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6">
            <TInputMoney
              label="Amount"
              class="mb-3"
              :currency="input.currency_id"
              :value.sync="input.amount"
            />
          </CCol>
        </CRow>
        <TInputTextarea
          label="Description"
          placeholder="Description"
          class="mb-3"
          :value.sync="input.description"
        />
        <CRow>
          <CCol>
            <TInputRadio
              label="Reference"
              :list="refTypes"
              :value.sync="temp.ref_type"
              custom
            />
            <SSelectOrder
              v-if="temp.ref_type === 'order'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectPurchaseOrder
              v-if="temp.ref_type === 'purchase'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectContract
              v-if="temp.ref_type === 'contract'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectSfa
              v-if="temp.ref_type === 'sfa'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectLadingBill
              v-if="temp.ref_type === 'lading_bill'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectGoodsDelivery
              v-if="temp.ref_type === 'goods_delivery'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
            <SSelectInvoice
              v-if="temp.ref_type === 'container'"
              :value.sync="temp.referenceable_id"
              class="mb-3"
            />
          </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol md="6">
            <SSelectAccount
              label="Debit"
              class="mb-3"
              :value.sync="input.debit_id"
            />
          </CCol>
          <CCol md="6">
            <SSelectAccount
              label="Credit"
              class="mb-3"
              :value.sync="input.credit_id"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol md="5">
        <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  expose: ["open"],
  props: {
    transaction_parent_id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      temp: {},
      input: {},
      items: [],
      receipts: [],
      type: "payment_service",
      transactionableTypes: [
        { value: "user", label: this.$t("Customer") },
        { value: "supplier", label: this.$t("Supplier") },
      ],
      refTypes: [
        { value: "order", label: this.$t("Order") },
        { value: "purchase", label: this.$t("Purchase") },
        { value: "contract", label: this.$t("Contract") },
        { value: "sfa", label: this.$t("SFA") },
        { value: "lading_bill", label: this.$t("Lading bill") },
        { value: "goods_delivery", label: this.$t("Goods delivery") },
        { value: "container", label: this.$t("Container") },
      ],
      arisingFromTypes: [
        { value: "card", label: this.$t("Card") },
        { value: "transaction", label: this.$t("Transaction") },
      ],
      show: false,
    };
  },
  computed: {
    creating() {
      return this.$store.getters["accounting.transactions.creating"];
    },
  },
  watch: {
    // "temp.arising_from": function () {
    //   this.$delete(this.input, "card_id");
    //   this.$delete(this.input, "code_ref");
    //   this.$delete(this.input, "transaction_parent_id");
    // },
    "input.transactionable_type": function () {
      this.$delete(this.input, "transactionable_id");
    },
    "temp.ref_type": function () {
      this.$delete(this.input, "referenceable_id");
    },
  },
  methods: {
    create() {

      // if (
      //   this.input.amount > this.temp.parent_transaction.transactions_remaining
      // ) {
      //   this.$store.commit("toasts.push", {
      //     title: this.$t("The given data was invalid"),
      //     message: this.$t(
      //       "The amount must be smaller than transaction remaining"
      //     ),
      //     type: "danger",
      //   });

      //   return;
      // }

      this.$store
        .dispatch("accounting.transactions.create", this.getDataCreate())
        .then(() => {
          this.clearInput();
          this.show = false;
          this.$emit("create_success");
        });
    },
    getDataCreate() {
      let params = { ...this.input };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      if (this.temp.ref_type && this.temp.referenceable_id) {
        const nextIndexReceipt = this.receipts.length;
        params[`receipts[${nextIndexReceipt}][receiptable_type]`] =
          this.temp.ref_type;
        params[`receipts[${nextIndexReceipt}][receiptable_id]`] =
          this.temp.referenceable_id;
      }

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    open(parent_transaction) {
      this.show = true;
      this.$set(this.input, "transaction_parent_id", parent_transaction.id);
      this.$set(this.input, "currency_id", parent_transaction.currency_id);
      this.$set(
        this.input,
        "amount",
        parent_transaction.transactions_remaining
      );
      this.$set(this.temp, "parent_transaction", parent_transaction);
    },
  },
};
</script>
