<template>
  <TModal
    title="Transaction"
    :show="show"
    size="xl"
    @update:show="$emit('update:show', $event)"
    :fetching="fetching"
  >
    <div v-if="transaction.id">
      <CRow>
        <CCol md="6">
          <TTableAsForm
            :data="transaction"
            :fields="t1Fields"
            :splitLeft="5"
            class="mb-3"
          >
            <template #card="{ value }">
              <SMessageCard
                :value="value"
                editable
                @change="updateTransaction('card_id', $event)"
              />
            </template>
            <template #code_ref="{ value }">
              <TMessageText
                :value="value"
                :messageOptions="{ noTranslate: true }"
                editable
                @change="updateTransaction('code_ref', $event)"
              />
            </template>
            <template #voucher_date="{ value }">
              <TMessageDateTime
                :content="value"
                dateOnly
                editable
                @change="updateTransaction('voucher_date', $event)"
              />
            </template>
            <template #object="{ value }">
              <SMessageWallet v-if="value" :data="transaction" />
            </template>
            <template #credit_order_id="{ value }">
              <TLink
                v-if="value"
                :content="value"
                :to="lodash.getReferenceLink('credit_order', value)"
              />
            </template>
            <template #currency_id="{ value }">
              <SMessageCurrency :id="value" />
            </template>
            <template #amount="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="transaction.currency_id"
                editable
                @change="updateTransaction('amount', $event)"
              />
            </template>
            <template #transactions_remaining="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="transaction.currency_id"
              />
            </template>
            <template #description="{ value }">
              <TMessageText
                :value="value"
                :messageOptions="{ noTranslate: true, truncate: 3 }"
                editable
                @change="updateTransaction('description', $event)"
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol md="6">
          <TTableAsForm
            :data="transaction"
            :fields="t2Fields"
            :splitLeft="5"
            class="mb-3"
          >
            <template #type_id="{ value }">
              <SMessageTransactionType :value="value" class="text-truncate-1" />
            </template>
            <template #prepared_by="{ value }">
              <SMessageUser :user="value" />
            </template>
            <template #debit_id="{ value }">
              <SMessageAccount
                :value="value"
                editable
                @change="updateTransaction('debit_id', $event)"
              />
            </template>
            <template #credit_id="{ value }">
              <SMessageAccount
                :value="value"
                editable
                @change="updateTransaction('credit_id', $event)"
              />
            </template>
            <template #created_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
            <template #updated_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
          </TTableAsForm>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <!-- Phát sinh từ -->

        <CCol md="12" class="mb-2">
          <TMessage content="Arising from" bold class="mb-2" />
          <SCardTransactionItem
            :transaction="transaction.parent"
            v-if="transaction.parent"
            @click="getRelatedTransaction(transaction.parent)"
          />
          <TMessageNotFound v-if="!transaction.parent" />
        </CCol>

        <!-- Đối tượng tham chiếu -->

        <CCol md="12" class="mb-2">
          <TMessage content="Reference" bold class="mb-2" />
          <TTableSimple
            :fields="referenceFields"
            :items="transaction.references || []"
            @update:create="onInputReferenceItem"
            noResult
          >
            <template #_-header>
              <div
                v-if="
                  !transaction ||
                  (!!transaction &&
                    transaction.references &&
                    !transaction.references.length)
                "
              >
                <TButton
                  icon="cil-plus"
                  size="sm"
                  tooltip="Add transaction reference"
                  variant="ghost"
                  @click="showModalAddNewReference = true"
                />
              </div>
            </template>
            <template #_="{ item }">
              <td>
                <TButtonRemove @click="onRemoveReference(item)" />
              </td>
            </template>
            <template #receiptable_type="{ item }">
              <td>
                <TMessage
                  :content="getContentReferenceType(item.receiptable_type)"
                />
              </td>
            </template>
            <template #receiptable_id="{ item }">
              <td>
                <TLink
                  :to="
                    lodash.getReferenceLink(
                      `${
                        item.receiptable_type == 'container'
                          ? 'invoice'
                          : item.receiptable_type
                      }`,
                      item.receiptable_id
                    )
                  "
                  :content="item.receiptable_id"
                />
              </td>
            </template>
          </TTableSimple>
        </CCol>

        <!-- Phát sinh -->

        <CCol md="12" class="mb-2">
          <TMessage content="Arising" bold class="mb-2" />
          <div
            v-for="transaction in transaction.children"
            :key="transaction.id"
          >
            <SCardTransactionItem
              :transaction="transaction"
              @remove="onRemoveChildTransaction(transaction)"
              @click="getRelatedTransaction(transaction)"
            />
          </div>
          <TMessageNotFound
            v-if="!(transaction.children ? transaction.children.length : false)"
          />
          <div class="d-flex justify-content-center">
            <TButton
              content="Distribute transaction"
              color="primary"
              @click="$refs.modalAddNewChildTransactionRef.open(transaction)"
              v-if="allow_add_child_transaction"
            />
          </div>
        </CCol>
      </CRow>

      <hr />

      <div class="w-100">
        <TInputFile
          :value.sync="fileRecords"
          @deleted="deleteFile"
          @selected="uploadReceipt"
          :options="{ linkable: true }"
        />
      </div>
    </div>

    <!-- Transaction receipts -->

    <template #actions>
      <div class="d-flex justify-content-start w-100">
        <TButtonDelete
          v-if="transaction.id && !deleting"
          @click="onDeleteTransaction"
          tooltip="Delete transaction"
          :options="{ content: 'Delete transaction' }"
          variant=""
        />
        <TSpinner v-else :loading="deleting" />
      </div>
    </template>

    <ModalAddNewTransactionReference
      :show.sync="showModalAddNewReference"
      :transaction_id="transaction.id"
      @create_success="onRefresh"
    />

    <ModalAddNewChildTransaction
      @create_success="onRefresh"
      ref="modalAddNewChildTransactionRef"
    />

    <ModalRelatedTransaction
      :show.sync="showModalRelatedTransaction"
      :transaction.sync="relatedTransaction.data"
      :loading="relatedTransaction.loading"
      @change-transaction="($event) => getRelatedTransaction($event)"
      @update="updateRelatedTransaction"
    />
  </TModal>
</template>

<script>
import ModalAddNewTransactionReference from "./ModalAddNewTransactionReference.vue";
import ModalAddNewChildTransaction from "./ModalAddNewChildTransaction.vue";
import ModalRelatedTransaction from "./ModalRelatedTransaction.vue";

export default {
  components: {
    ModalAddNewTransactionReference,
    ModalAddNewChildTransaction,
    ModalRelatedTransaction,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      required: false,
    },
    allow_add_child_transaction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("accounting.transaction_types.fetch.if-first-time");
  },
  data() {
    return {
      data: {},
      fileRecords: [],
      t1Fields: [
        { key: "card", label: "Card" },
        { key: "code_ref", label: "Code ref" },
        { key: "object", label: "Object" },
        { key: "voucher_date", label: "Voucher date" },
        { key: "credit_order_id", label: "Credit card" },
        { key: "currency_id", label: "Currency" },
        { key: "amount", label: "Amount" },
        { key: "transactions_remaining", label: "Transactions remaining" },
        { key: "description", label: "Description" },
      ],
      t2Fields: [
        { key: "id", label: "ID" },
        { key: "type_id", label: "Type" },
        { key: "prepared_by", label: "Prepared by" },
        { key: "debit_id", label: "Debit" },
        { key: "credit_id", label: "Credit" },
        { key: "created_at", label: "Created at" },
        { key: "updated_at", label: "Updated at" },
      ],
      referenceFields: [
        {
          key: "_",
          label: "#",
        },
        {
          key: "receiptable_type",
          label: "Object",
        },
        {
          key: "receiptable_id",
          label: "ID",
        },
      ],
      relatedTransaction: {
        data: {},
        loading: false,
      },
      showModalAddNewReference: false,
      showModalRelatedTransaction: false,
    };
  },
  watch: {
    transaction(value) {
      this.fileRecords = value.files?.map((receipt) => {
        return {
          receipt_id: receipt.id,
          name: receipt.receiptable_id,
          size: receipt.receiptable_info?.size,
          type: receipt.receiptable_info?.mime_type,
          ext: receipt.receiptable_info?.extension,
          url:
            process.env.VUE_APP_ACCOUNTING_SERVICE_HOST +
            "/files/" +
            receipt.receiptable_id,
        };
      });
    },
    id(id) {
      if (!id) {
        return;
      }
      this.$store.dispatch("accounting.transactions.detail.fetch", this.id);
    },
  },
  computed: {
    transaction() {
      return this.$store.getters["accounting.transactions.detail"];
    },
    fetching() {
      return this.$store.getters["accounting.transactions.detail.fetching"];
    },
    transaction_detail_query() {
      return this.$store.getters["accounting.transactions.detail.query"];
    },
    deleting() {
      return this.$store.getters["accounting.transactions.deleting"];
    },
  },
  methods: {
    onInputReferenceItem() {},
    updateTransaction(field, data) {
      this.$store
        .dispatch("accounting.transactions.detail.update", {
          [field]: data,
        })
        .then(() => {
          this.$emit("updated");
        });
    },
    deleteFile(file) {
      if (file.receipt_id) {
        this.$store.dispatch("accounting.transaction_receipts.delete", file.receipt_id);
      }
    },
    uploadReceipt(files) {
      Promise.all(
        files.map((file) =>
          this.$store.dispatch("accounting.transaction_receipts.create", {
            transaction_id: this.transaction.id,
            receiptable_type: "file",
            file: file.file,
          })
        )
      ).finally(() => {
        this.$store.dispatch("accounting.transactions.detail.refresh");
      });
    },
    onRemoveReference(item) {
      this.$tomoni.accounting.transaction_receipts
        .delete(item.id)
        .then(() => {
          this.onRefresh();
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    onRefresh() {
      this.$store.dispatch("accounting.transactions.detail.refresh");
    },
    onRemoveChildTransaction(t) {
      this.$tomoni.accounting.transactions
        .delete(t.id)
        .then(() => {
          this.onRefresh();
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    getRelatedTransaction(t) {
      this.relatedTransaction.loading = true;
      this.showModalRelatedTransaction = true;

      this.$tomoni.accounting.transactions
        .get(t.id, this.transaction_detail_query)
        .then(({ data }) => {
          this.relatedTransaction.data = data;
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.relatedTransaction.loading = false;
        });
    },
    updateRelatedTransaction(field, data, t) {
      this.relatedTransaction.loading = true;

      this.$tomoni.accounting.transactions
        .update(t.id, { [field]: data })
        .then(() => {
          this.getRelatedTransaction(t);
        })
        .catch((error) => {
          this.relatedTransaction.loading = false;
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    onDeleteTransaction() {
      this.$store
        .dispatch("accounting.transactions.delete", this.id)
        .then(() => {
          this.$emit("update:show", false);
        });
    },
    getContentReferenceType(type) {
      switch (type) {
        case "purchase":
          return "Purchase order";
        case "order":
          return "Sale order";
        case "goods_delivery":
          return "Goods delivery";
        case "lading_bill":
          return "Lading bill";
        case "sfa":
          return "SFA";
        case "credit_order":
          return "Order credit";
        default:
          return this.lodash.upperFirst(type);
      }
    },
  },
};
</script>
