<template>
  <TModal
    title="Add transaction reference"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <div class="d-flex w-100">
        <TButton
          :options="{ disabled: !temp.receiptable_id }"
          variant="outline"
          content="Select"
          icon="cil-check"
          class="ml-auto"
          @click="onSave"
        />
      </div>
    </template>

    <SCardSelectReferenceReceipt :receipt.sync="temp" />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    transaction_id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      temp: {},
    };
  },
  watch: {
    show() {
      this.temp = {};
    },
  },
  methods: {
    onSave() {
      const { receiptable_type, receiptable_id } = this.temp;

      this.$store
        .dispatch("accounting.transaction_receipts.create", {
          transaction_id: this.transaction_id,
          ...{ receiptable_type, receiptable_id },
        })
        .then((data) => {
          this.$emit("create_success", data);
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
